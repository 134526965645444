<template>
  <div class="yh_wrapper">
    <div v-if="examList && examList.length">
      <template v-for="(item, ind) in examList">
        <div class="yh_list" :key="ind + 'ind'">
          <header>
            <p>考试时间：{{ item.updateTime }}</p>
            <p class="yh_exam_num">
              考试次数：{{ item.tryCount }}次
              <span :class="['yh_pass', item.passed ? 'bg_blue' : 'bg_gray']">
                {{ item.passed ? "通过考试" : "未通过" }}
              </span>
            </p>
          </header>
          <main>
            <div class="yh_main_wra">
              <h2>{{ item.title }}</h2>
              <div class="yh_total_box">
                <span class="yh_total color_blue"
                  >{{ item.totleNum || 0 }}题</span
                >
                <span class="yh_examtime">
                  限时{{ item.examDTO.totalTime }}分钟 &nbsp;&nbsp;&nbsp;&nbsp;
                  满分{{ item.examDTO.totalScore }}分
                  {{ item.examDTO.qualifyScore }}分及格
                </span>
              </div>
            </div>
            <div class="yh_exam_c"></div>
            <div class="yh_exam_score">
              <span>最高分：</span>
              <i>{{ item.maxScore }}</i>
            </div>
            <div class="yh_exam_detailBtn bg_blue" @click="examDetail(item)">
              <span>考试详情</span>
            </div>
          </main>
        </div>
      </template>
      <pagination
        v-if="total > 0"
        :pageSize="pageSize"
        :total="total"
        @pageChange="pageChange"
        @sizeChange="sizeChange"
      />
    </div>
    <none-data :searchType="true" v-else />
    <el-dialog title="考试详情" :visible.sync="dialogShow">
      <YForm v-model="formValues">
        <YQueryTable :serve="getDetail" ref="queryTable">
          <template>
            <el-table-column
              label="考试时间"
              prop="createTime"
              show-overflow-tooltip
              width="120"
            />
            <el-table-column label="分数" prop="objScore" />
            <el-table-column label="是否通过" prop="pass" />
            <el-table-column label="考试用时" prop="userTime" />
            <el-table-column label="操作">
              <template slot-scope="props">
                <el-link
                  :disabled="!canReview"
                  @click="papaerDetail(props.row)"
                >
                  {{ canReview ? "查看详情" : "不可查看" }}
                </el-link>
              </template>
            </el-table-column>
          </template>
        </YQueryTable>
      </YForm>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@comps/backStageComponent/pagination";
import noneData from "@comps/backStageComponent/noneData";
import {
  fetchMyExam,
  fetchExamDetail,
} from "@/apis/studentsEndAPI/exam/index.js";
export default {
  name: "examination",
  components: {
    Pagination,
    noneData,
  },
  data() {
    return {
      total: 2,
      currentPage: 1,
      pageSize: 10,
      dialogShow: false,
      formValues: {},
      examList: [{ examDTO: {} }],
      examId: "",
      canReview: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    pageChange(val) {
      this.currentPage = val;
      this.getList();
    },
    sizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    async getDetail({ params }) {
      const { data } = await fetchExamDetail({
        current: params.currentPage,
        size: params.pageSize,
        params: {
          examId: this.examId,
        },
      });
      const list = (data.records || []).map((e) => {
        return {
          ...e,
          userTime: `${e.userTime}分钟`,
          pass: e.objScore >= e.qualifyScore ? "是" : "否",
        };
      });
      return {
        data: list,
        total: data?.total,
      };
    },
    async examDetail(exam) {
      const { examId, examDTO } = exam;
      const { times, timeLimit } = examDTO;
      this.canReview = false;
      if (!timeLimit || (!times && times !== 0)) {
        this.canReview = true;
      }
      this.examId = examId;
      this.dialogShow = true;
      this.$nextTick(this.$refs.queryTable.runServe);
    },
    async papaerDetail(row) {
      this.$router.push({ name: "ShowExam", params: { id: row.id } });
    },
    async getList() {
      const { data } = await fetchMyExam({
        current: this.currentPage,
        params: { title: "" },
        size: this.pageSize,
        t: Date.now(),
      });
      const { records = [], total = 0 } = data || {};
      this.examList = records || [];
      this.total = total;
    },
  },
};
</script>

<style lang="scss" scoped>
.yh_wrapper {
  padding: 0 30px;
  width: 100%;

  .yh_list {
    margin-bottom: 18px;
    padding: 10px;

    header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px 0 15px;
      width: 942px;
      height: 42px;
      background: #f7f7f7;

      p {
        color: #333;
        font-size: 12px;
        line-height: 17px;
      }

      .yh_exam_num {
        margin-right: 75px;

        .yh_pass {
          position: absolute;
          top: 9px;
          right: 0;
          display: inline-block;
          width: 84px;
          height: 24px;
          border-radius: 100px 0 0 100px;
          color: #fff;
          text-align: center;
          line-height: 24px;
        }
      }
    }

    main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      background: #fff;

      .yh_main_wra {
        padding: 22px 5px;

        h2 {
          margin-bottom: 10px;
          color: #333;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }

        p {
          margin-bottom: 16px;
          color: #91929e;
          line-height: 20px;
        }

        .yh_total_box {
          .yh_total {
            display: inline-block;
            margin-right: 18px;
            width: 73px;
            height: 24px;
            border-radius: 12px;
            background: #e8f1ff;
            text-align: center;
            font-size: 12px;
            line-height: 24px;
          }

          .yh_examtime {
            display: inline-block;
            height: 17px;
            color: #91929e;
            font-size: 12px;
            line-height: 17px;
          }
        }
      }

      .yh_exam_c {
        height: 17px;
        color: #91929e;
        font-size: 12px;
        line-height: 17px;
      }

      .yh_exam_score {
        position: relative;

        span {
          display: inline-block;
          color: #333;
          font-size: 15px;
          line-height: 21px;
        }

        i {
          position: absolute;
          top: -14px;
          left: 60px;
          display: inline-block;
          color: #e03535;
          font-weight: bold;
          font-style: normal;
          font-size: 40px;
          font-family: DINAlternate-Bold, DINAlternate;
          line-height: 47px;
        }
      }

      .yh_exam_detailBtn {
        margin-right: 20px;
        width: 108px;
        height: 30px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;

        span {
          color: #fff;
          font-weight: bold;
          font-size: 13px;
          line-height: 30px;
        }
      }
    }
  }
}

.bg_gray {
  background: #fdc748;
}
</style>
